import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import * as Yup from "yup";
import { useElements, useStripe, CardElement } from "@stripe/react-stripe-js";
import NavigationBar from "../../components/NavigationBar/NavigationBar";
import { useUser } from "../../context/userContext";
import SubmitButton from "../../components/Common/SubmitButton";
import { callAPI } from "../../services/apiService";
import { Toast } from "../../components/Common/Toast";
import CheckoutForm from "../../components/Stripe/CheckoutForm";
import Heading from "../../components/Common/Heading";
import ShowValidationError from "../../components/Common/ValidationError";
import {
  ADDITIONAL_PAGE_FEE,
  BOOK_PURCHASE_REQUEST,
  COUPON_FOR_BOOK_PURCHASE,
  GOAFFPRO_PUBLIC_KEY,
  HTTP_STATUS_200,
  LOCAL_STORAGE_BOOK_ID,
  LOCAL_STORAGE_EDIT_MODE,
  LOCAL_STORAGE_STORY_STEP,
} from "../../helper/Constant";
import { getUSStates, goToTopOfPage, getCountries } from "../../helper/helper";
import { debounce } from "../../helper/Debounce";
import BookCoverImage from "./BookCoverImage";
import { useApp } from "../../context/appContext";
import Footer from "../../components/Footer/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand } from "@fortawesome/free-solid-svg-icons";
import Loader from "../../components/Common/Loader";
import { init, trackPageView, trackConversion } from "goaffpro-client";
import { IconChecked } from "../Builder/builderHelpers";
import Guide from "../../components/Common/Guide";

init(GOAFFPRO_PUBLIC_KEY);

const validationSchema = Yup.object().shape({
  shippingEmail: Yup.string().email("Invalid email address").required("Email is required"),
  customerName: Yup.string().required("Name is required"),
  shippingAddress1: Yup.string().required("Address is required"),
  shippingState: Yup.string().required("State is required"),
  shippingCity: Yup.string().required("City is required"),
  shippingCountry: Yup.string().required("Country is required"),
  shippingZip: Yup.string().required("Zip is required"),

  // nameOnCard: Yup.string().required("Name on Card is required"),

  billingCardHolderName: Yup.string().required(" Name is required"),
  billingAddress1: Yup.string().required("Address is required"),
  billingCity: Yup.string().required("City is required"),
  billingPostalCode: Yup.string().required("Zip is required"),
  billingState: Yup.string().required("State is required"),
});

const BookPurchase = () => {
  const navigate = useNavigate();
  let { bookId } = useParams();
  const { user } = useUser();
  const { setDraftId, singleBookPlan } = useApp();
  const stripe = useStripe();
  const elements = useElements();
  const [usaStates, setUsaStates] = useState([]);
  const [countries, setCountries] = useState([]);
  const [bookDetails, setBookDetails] = useState(null);
  const [isBillingSameAsShipping, setIsBillingSameAsShipping] = useState(true);
  const [paymentLoader, setPaymentLoader] = useState(false);
  const [couponLoader, setCouponLoader] = useState(false);
  const [errors, setErrors] = useState({});
  const [purchaseSuccess, setPurchaseSuccess] = useState(false);
  const [bookCost, setBookCost] = useState(12.95);
  const [bookQty, setBookQty] = useState(1);
  const [shippingCost, setShippingCost] = useState(6.99);
  const [couponValidationLoading, setCouponValidationLoading] = useState(false);
  const [discount, setDiscount] = useState();
  const [additionalPageCost, setAdditionalPageCost] = useState(0);
  const [bookTotalCost, setBookTotalCost] = useState(null);
  const [couponClass, setCouponClass] = useState(null);
  const [netCost, setNetCost] = useState(null);
  // const [taxCalculated, setTaxCalculated] = useState(false);
  // const [tax, setTax] = useState(null);
  // const [taxLoader, setTaxLoader] = useState(false);

  const [values, setValues] = useState({
    shippingEmail: "",
    customerName: "",
    shippingAddress1: "",
    shippingAddress2: "",
    shippingState: "",
    shippingCountry:"United States",
    shippingCity: "",
    shippingZip: "",
    billingCardHolderName: "",
    billingAddress1: "",
    billingAddress2: "",
    billingCity: "",
    billingPostalCode: "",
    coupon: "",
    qty: 1,
    billingState: "",
  });

  useEffect(() => {
    setUsaStates(getUSStates());
    setCountries(getCountries());

    //goaffpro page tracking
    trackPageView();
  }, []);

  // useEffect(() => {
  //     if (bookId && taxCalculated=== false) {
  //       if (
  //         (values.shippingAddress2 || values.shippingAddress1) &&
  //         values?.shippingCity &&
  //         values.shippingState &&
  //         values?.shippingZip &&
  //         bookTotalCost
  //       ){
  //         getTax();
  //         console.log("tax api hit")
  //       }
  //     }
  //   }, [values]);

  // const handleBlur = (e) => {
  //   const { name, value } = e.target;

  //   if (bookId && taxCalculated === false) {
  //     if (
  //       (values.shippingAddress2 || values.shippingAddress1.trim()) &&
  //       values?.shippingCity.trim() &&
  //       values.shippingState.trim() &&
  //       values?.shippingZip.trim() &&
  //       bookTotalCost
  //     ) {
  //       getTax();
  //     }
  //   }
  // };

  // const getTax = async () => {
  //   try {
  //     setTaxLoader(true);
  //     const lineItems = [
  //       {
  //         amount: bookTotalCost,
  //         reference: bookId,
  //       },
  //     ];

  //     const customerDetails = {
  //       address: {
  //         line1: values?.shippingAddress1 + " " + values?.shippingAddress2,
  //         city: values?.shippingCity,
  //         state: values?.shippingState,
  //         postal_code: values?.shippingZip,
  //         country: "US",
  //       },
  //       address_source: "shipping",
  //     };

  //     const data = {
  //       customerDetails,
  //       lineItems,
  //     };

  //     const taxCalculated = await callAPI(`api/book-purchase/get-stripe-tax`, { data });
  //     if (taxCalculated?.status === HTTP_STATUS_200 && parseFloat(taxCalculated.message) > 0) {
  //       setTax(parseFloat(taxCalculated.message));
  //       setTaxCalculated(true);
  //       setTaxLoader(false);
  //     } else Toast("Unable to calculate Tax for this address. Please verify to proceed!", true);
  //   } catch (error) {
  //     setTaxLoader(false);
  //     setTaxCalculated(false);
  //     Toast("Something went wrong while calculating Tax, Please try again!", true);
  //   }
  // };

  useEffect(() => {
    setValues({
      ...values,
      shippingEmail: user?.email || "",
      customerName: user?.customerName || "",
      shippingAddress1: user?.shippingAddress1 || "",
      shippingAddress2: user?.shippingAddress2 || "",
      shippingState: user?.shippingState || "",
      shippingCountry: user?.shippingCountry || "United States",
      shippingCity: user?.shippingCity || "",
      shippingZip: user?.shippingZip || "",
      billingCardHolderName: user?.billingCardHolderName || "",
      billingAddress1: user?.billingAddress1 || "",
      billingAddress2: user?.billingAddress2 || "",
      billingCity: user?.billingCity || "",
      billingPostalCode: user?.billingPostalCode || "",
      coupon: discount ? user?.coupon : "",
      billingState: user?.billingState || "",
    });

    // //if all inputs call tax calculation api
    // if (
    //   (values.shippingAddress2 || values.shippingAddress1.trim()) &&
    //   values?.shippingCity.trim() &&
    //   values.shippingState.trim() &&
    //   values?.shippingZip.trim() &&
    //   bookTotalCost
    // ) {
    //   getTax();
    // }
  }, [user]);

  const getBookDetails = async () => {
    try {
      const bookDetails = await callAPI(`api/getBookDetails/${bookId}`);
      if (bookDetails?.book) {
        setBookDetails(bookDetails?.book);
        let totalPages = bookDetails?.book?.pageTotal;

        if (totalPages > 16) {
          let extraCost = (((totalPages - 16) / 4) * ADDITIONAL_PAGE_FEE).toFixed(2);
          setAdditionalPageCost(extraCost);

          // setBookTotalCost(
          //   (
          //     (parseFloat(bookCost) + parseFloat(additionalPageCost)) * parseFloat(bookQty) +
          //     parseFloat(shippingCost)
          //   ).toFixed(2)
          // );
        } else if (totalPages === 16) setAdditionalPageCost(0);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (bookId) getBookDetails();
  }, [bookId]);

  useEffect(() => {
    localStorage.removeItem(LOCAL_STORAGE_BOOK_ID);
    localStorage.removeItem(LOCAL_STORAGE_EDIT_MODE);
    localStorage.removeItem(LOCAL_STORAGE_STORY_STEP);
    setDraftId(null);
    goToTopOfPage();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    if (name === "qty") {
      setBookQty(value);
      // setTaxCalculated(false);
    }
    if (
      name === "shippingAddress1" ||
      name === "shippingAddress2" ||
      name === "shippingCity" ||
      name === "shippingCountry" ||
      name === "shippingState" ||
      name === "shippingZip"
    ) {
      // setTaxCalculated(false);
    }

    copyShippingToBilling();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await validationSchema.validate(values, { abortEarly: false });
      if (stripe) {
        // if (!stripe || !elements) return;

        setPaymentLoader(true);

        const resultStripe = await stripe.createPaymentMethod({
          type: "card",
          card: elements.getElement(CardElement),
          billing_details: {
            email: user?.email,
          },
        });
        if (resultStripe?.error) {
          setPaymentLoader(false);
          Toast(resultStripe?.error?.message, true);
        } else {
          const paymentResponse = await callAPI("api/book-purchase/place-order", {
            paymentMethod: resultStripe.paymentMethod.id,
            bookId,
            shippingEmail: user?.email,
            customerName: values?.customerName,
            shippingAddress1: values?.shippingAddress1,
            shippingAddress2: values?.shippingAddress2,
            shippingState: values?.shippingState,
            shippingCountry: values?.shippingCountry,
            shippingCity: values?.shippingCity,
            shippingZip: values?.shippingZip,
            billingCardHolderName: values?.billingCardHolderName,
            billingAddress1: values?.billingAddress1,
            billingAddress2: values?.billingAddress2,
            billingCity: values?.billingCity,
            billingState: values?.billingState,
            billingPostalCode: values?.billingPostalCode,
            coupon: values?.coupon.toUpperCase(),
            bookQty,
            requestType: COUPON_FOR_BOOK_PURCHASE,
            additionalPageCost,
            // taxAmount: parseFloat(tax),
          });
          if (paymentResponse?.error || paymentResponse?.status !== HTTP_STATUS_200)
            Toast(paymentResponse?.error || paymentResponse?.message, true);
          else {
            Toast("Book Purchased Successfully");
            setPurchaseSuccess(true);
            trackConversion({
              // id: "1001", // the internal order ID of your system (useful if you are using REST JSON file)
              number: paymentResponse?.paymentId, // human readable order number displayed to the admins
              // total: bookTotalCost + parseFloat(tax), // the order total (the final amount that the customer paid)
              total: bookTotalCost, // the order total (the final amount that the customer paid)
              // subtotal: 850, // order subtotal (order total minus shipping and taxes)
              // discount: 50, // the discount received by the customer
              // tax: 100, // the tax charged on the order
              // shipping: 50, // the shipping charged on the order
              currency: "USD", //ISO-4217 three letter currency code of the order
              // date: "2021-04-27T17:06:55.450Z",
              /*customer: {
              // customer details
              first_name: "John",
              last_name: "Doe",
              email: "johndoe@example.com",
              phone: "+1 555-111-222", // optional
              is_new_customer: true, //optional
            },//*/
              // coupons: ["goaffpro10"],
            });

            goToTopOfPage();
            // navigate("/");
          }
        }
      } else {
        // Toast("Valid Address Required to Calculate Tax", true);
      }
      setPaymentLoader(false);
    } catch (error) {
      if (error.name === "ValidationError") {
        const yupErrors = {};
        error.inner.forEach((err) => {
          yupErrors[err.path] = err.message;
        });
        setErrors(yupErrors);
      }
    }
  };

  // useEffect(() => {
  //   let newCost = 0;

  //   let discountAmountPerBook = discount ? parseFloat(discount) : 0;
  //   let singlebookCost = parseFloat(bookCost || 0) + parseFloat(additionalPageCost || 0);

  //   if (!discount) {
  //     newCost = (
  //       parseFloat(parseFloat(singlebookCost) * parseFloat(bookQty)) + parseFloat(shippingCost)
  //     ).toFixed(2);
  //   } else {
  //     newCost = (
  //       parseFloat((parseFloat(singlebookCost) - parseFloat(discountAmountPerBook)) * parseFloat(bookQty)) +
  //       parseFloat(shippingCost)
  //     ).toFixed(2);
  //     if (newCost < 0) newCost = parseFloat(shippingCost).toFixed(2);
  //   }

  //   setBookTotalCost(newCost);
  // }, [bookCost, additionalPageCost, discount, bookQty]);

  useEffect(() => {
    let newbookCost = 0;
    let newNetCost = 0;

    newbookCost = parseFloat(bookCost || 0) + parseFloat(additionalPageCost || 0);

    let discountAmountPerBook = discount ? parseFloat(discount) : 0;

    if (!discount) {
      newNetCost = (
        parseFloat(parseFloat(newbookCost) * parseFloat(bookQty)) + parseFloat(shippingCost)
      ).toFixed(2);
    } else {
      newNetCost = (
        parseFloat((parseFloat(newbookCost) - parseFloat(discountAmountPerBook)) * parseFloat(bookQty)) +
        parseFloat(shippingCost)
      ).toFixed(2);
      if (newNetCost < 0) newNetCost = parseFloat(shippingCost).toFixed(2);
    }

    setBookTotalCost((parseFloat(newbookCost) * parseFloat(bookQty)).toFixed(2));
    setNetCost(newNetCost);
  }, [bookCost, additionalPageCost, discount, bookQty]);

  const copyShippingToBilling = () => {
    if (isBillingSameAsShipping) {
      setValues((prevValues) => ({
        ...prevValues,
        billingCardHolderName: prevValues.customerName,
        billingAddress1: prevValues.shippingAddress1,
        billingAddress2: prevValues.shippingAddress2,
        billingCity: prevValues.shippingCity,
        billingPostalCode: prevValues.shippingZip,
        billingState: prevValues.shippingState,
      }));
    }
  };

  // const calculateTotalCost = ()=>{
  //   const cost = parseFloat(
  //     parseFloat((bookCost * bookQty + shippingCost).toFixed(2)) + parseFloat(additionalPageCost)
  //   ).toFixed(2);
  //   return cost;
  // }

  const goBack = () => {
    navigate(-1);
  };
  useEffect(() => {
    if (isBillingSameAsShipping) copyShippingToBilling();
  }, [isBillingSameAsShipping]);

  const handleApplyPromoCode = async (couponCode) => {
    setCouponLoader(true);
    const response = await callAPI("user/coupon-validate", {
      couponCode,
      amount: (parseFloat(bookCost || 0) + parseFloat(additionalPageCost || 0)) * parseFloat(bookQty),
      requestType: COUPON_FOR_BOOK_PURCHASE,
      email: user?.email,
    });

    if (response.status === HTTP_STATUS_200) {
      setCouponClass("text-storygreen");
      let discountedPrice = parseFloat(response?.data?.discount).toFixed(2);

      setDiscount(discountedPrice);
      setCouponLoader(false);
      Toast("Coupon Applied");
    } else {
      setDiscount(null);
      setCouponClass("text-storyred");
      Toast("Invalid Coupon", true);
      setCouponLoader(false);
    }
  };

  return (
    <div className="App mx-auto bg-storytan">
      <NavigationBar />
      <div className={`max-w-7xl bg-storytan p-8 mx-auto`}>
        {purchaseSuccess ? (
          <div className="flex flex-col justify-center items-center gap-2 h-[82vh]">
            <p className="text-2xl">
              Thank you for the order!
              <br />
              <br /> Would you like to create a new story?
            </p>
            <Link
              to="/"
              className="w-[20%] h-8 flex justify-center items-center text-white text-base bg-storyblue rounded-md shadow-xl leading-none font-bold p-2 capitalize"
            >
              Create New Story
            </Link>
          </div>
        ) : (
          <div>
            <div className="flex justify-between items-baseline">
              {/* <Heading title="Purchase Your Book" /> */}
              <div className=" flex items-center align-end mb-4">
                <Heading title="Purchase Your Book" />
                <div className="mt-1">
                  <Guide />
                </div>
              </div>
              <button
                className="px-4 bg-storygreen text-white font-bold rounded-md text-sm h-11"
                onClick={() => {
                  goBack();
                }}
              >
                Back
              </button>
            </div>
            <div className="flex flex-col sm:flex-row md:mt-8">
              <div className="flex flex-col gap-8 mt-8 md:mt-0 text-left w-full mr-8">
                {/* <h1 className="text-storyblue text-xl">Book Cost: $ {bookCost}</h1>
                <h1 className="text-storyblue text-xl">Book Qty: {bookQty}</h1>
                <h1 className="text-storyblue text-xl">Shipping & Handling: $ {shippingCost}</h1>
                <h1 className="text-storyblue text-2xl">
                  Total Amount: $ {bookCost * bookQty + shippingCost}
                </h1>
                {shippingCost && (
                  <>
                    <p className="text-base ">
                      Please note that the shipping and handling cost is <strong>${shippingCost}</strong>, and
                      all packages are shipped via the <strong>United States Postal Service (USPS)</strong>{" "}
                      for timely and secure delivery.
                    </p>
                  </>
                )} */}

                <div className="flex flex-col justify-between rounded-3xl bg-white p-8 shadow-xl ring-1 ring-gray-900/10 sm:p-10">
                  <div>
                    <div className="flex justify-between flex-col items-center">
                      <h3
                        id="tier-hobby"
                        className=" text-left text-3xl font-semibold leading-7 text-storyblue"
                      >
                        Printed Book
                      </h3>
                      {/* <div className="mt-0 flex items-baseline gap-x-2"> */}
                      {/* <span className="text-2xl font-bold tracking-tight text-gray-900">
                          ${(bookCost * bookQty + shippingCost).toFixed(2)}
                        </span> */}
                      {/* </div> */}

                      <div className="flex  flex-col items-end">
                        <div className="w-[50%] border-yellow-700"></div>
                        <div>
                          <h1 className="text-storyblue text-base mt-2 border-red-300 flex justify-between">
                            <span>Book Qty:</span> {bookQty}
                          </h1>
                          <h1 className="text-storyblue text-base mt-2 flex justify-between">
                            <span>Book Cost:</span> $ {bookCost}
                          </h1>
                          {additionalPageCost > 0 && (
                            <h1 className="text-storyblue text-base mt-2 flex justify-between">
                              <span>Additional Pages Cost:</span> $ {additionalPageCost}
                            </h1>
                          )}

                          <hr className="mt-4" />
                          {bookCost && bookQty && shippingCost && (
                            <h1 className="text-storyblue text-base mt-2 flex justify-between">
                              <span> Book Total Cost:</span>{" "}
                              <span className="text-lg font-bold tracking-tight text-gray-900">
                                $ {bookTotalCost}
                              </span>
                            </h1>
                          )}
                          {shippingCost && (
                            <h1 className="text-storyblue text-base mt-2 flex gap-2 justify-between">
                              <span> Shipping & Handling:</span> $ {shippingCost}
                            </h1>
                          )}

                          {/* { taxCalculated && tax > 0 && ( */}
                          {/* <h1
                            className="text-storyblue mt-2 flex gap-2 justify-between 
                               text-xs italic font-bold"
                            
                          >
                            <span> Tax:</span>{" "}
                            {taxLoader
                              ? "Calculating Tax..."
                              : taxCalculated && tax > 0
                              ? `$ ${tax}`
                              : "Waiting for Address"}
                          </h1> */}
                          {/* )} */}
                          {discount && (
                            <>
                              <hr className="mt-4" />

                              <h1 className="text-storyblue text-base mt-2 flex justify-between items-center">
                                <span>Discount Amount:</span>{" "}
                                <span className="text-lg font-bold tracking-tight text-gray-900">
                                  ${" "}
                                  {discount > parseFloat(bookCost) + parseFloat(additionalPageCost)
                                    ? (parseFloat(bookCost) + parseFloat(additionalPageCost)).toFixed(2)
                                    : discount}
                                </span>
                              </h1>
                            </>
                          )}

                          {netCost && (
                            <>
                              <hr className="mt-4" />

                              <h1 className="text-storyblue text-base mt-2 flex justify-between items-center">
                                <span>Net Amount:</span>{" "}
                                {/* <span
                                  className={`font-bold tracking-tight text-gray-900 ${
                                    taxCalculated && tax > 0 && netCost ? "text-xl" : "text-xs italic"
                                  }`}
                                >
                                  {taxLoader
                                    ? "Calculating Total..."
                                    : taxCalculated && tax > 0 && netCost
                                    ? `$ ${(parseFloat(netCost) + parseFloat(tax)).toFixed(2)}`
                                    : `$ ${parseFloat(netCost)}`}
                               
                                </span>  */}
                                <span className="font-bold tracking-tight text-gray-900 text-xs italic">
                                  {/* {taxLoader
                                    ? "Calculating Total..."
                                    : taxCalculated && tax > 0 && netCost
                                    ? `$ ${(parseFloat(netCost) + parseFloat(tax)).toFixed(2)}`
                                    : `$ ${parseFloat(netCost)}`} */}
                                  $ {parseFloat(netCost)}
                                </span>
                              </h1>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <BookCoverImage bookDetails={bookDetails} />
                    <div className="w-full flex justify-center items-center gap-2" title="Book Cover Size">
                      {/* <FontAwesomeIcon icon={faExpand} className="text-storygreen text-2xl" /> */}
                      <h1 className="text-storygreen text-sm">
                        <strong>Size : </strong>8.5" x 8.5" (21.59 cm x 21.59 cm)
                      </h1>
                    </div>
                    <ul role="list" className="mt-10 space-y-4 text-sm leading-6 text-gray-600">
                      <li className="flex gap-x-3 text-left">
                        {IconChecked()}
                        {/* A printed book, rich with AI-crafted storytelling in your chosen art style */}A 16
                        page printed book, rich with AI-crafted storytelling in you chosen art style.
                        Additional pages will incur separate charges
                      </li>
                      <li className="flex gap-x-3 text-left">
                        {IconChecked()}
                        High-quality print, perfect for hands-on reading experiences. Shipped to your address.
                      </li>
                    </ul>
                  </div>
                  {/* <Link
                          onClick={() => navigateTo(`/bookpurchase/${bookId}`)}
                          to="#"
                          rel="noopener noreferrer"
                          className="mt-8 block h-11 rounded-md bg-storyblue px-3.5 py-2 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          Purchase Printed Book
                        </Link> */}
                </div>
              </div>
              <div className="w-full flex flex-col items-start gap-4">
                <h1 className="mt-4 md:mt-0 text-storyblue text-xl ">Shipping Information</h1>
                <form
                  id="form-purchase"
                  name="form-purchase"
                  className="mt-8 text-start w-full flex flex-col items-start gap-2"
                  onSubmit={handleSubmit}
                >
                  <div className="w-full">
                    <input
                      type="text"
                      name="shippingEmail"
                      value={user?.email}
                      onChange={handleChange}
                      placeholder="Email"
                      className="p-2 w-full rounded-md text-[#9ea4b0]"
                      readOnly
                    />
                    <ShowValidationError error={errors.shippingEmail} />
                  </div>
                  <h1 className="text-[#a1a1a1] text-md  mt-6">Shipping Address</h1>
                  <div className="w-full">
                    <input
                      type="text"
                      name="customerName"
                      value={values.customerName}
                      onChange={handleChange}
                      placeholder="Name"
                      className="p-2 w-full rounded-md"
                    />
                    <ShowValidationError error={errors.customerName} />
                  </div>
                  <div className="w-full">
                    <input
                      type="text"
                      name="shippingAddress1"
                      value={values.shippingAddress1}
                      onChange={handleChange}
                      placeholder="Address 1"
                      className="p-2 w-full rounded-md"
                      // onBlur={handleBlur}
                    />
                    <ShowValidationError error={errors.shippingAddress1} />
                  </div>
                  <div className="w-full">
                    <input
                      type="text"
                      name="shippingAddress2"
                      value={values.shippingAddress2}
                      onChange={handleChange}
                      placeholder="Address 2"
                      className="p-2 w-full rounded-md"
                      // onBlur={handleBlur}
                    />
                  </div>
                  <div className="flex gap-7 mt-1">
                    <div>
                      <input
                        type="text"
                        name="shippingCity"
                        value={values.shippingCity}
                        onChange={handleChange}
                        placeholder="City"
                        className="p-2 w-full rounded-md"
                        // onBlur={handleBlur}
                      />
                      <ShowValidationError error={errors.shippingCountry} />
                    </div>
                    {/* <div>
                      <select
                        name="shippingState"
                        value={values.shippingState}
                        onChange={handleChange}
                        placeholder="State"
                        className="p-[0.6rem] w-full rounded-md"
                        // onBlur={handleBlur}
                      >
                        <option value="" ></option>
                        {usaStates.map((state) => (
                          <option key={state} value={state}>
                            {state}
                          </option>
                        ))}
                      </select>
                      <ShowValidationError error={errors.shippingState} />
                    </div> */}
                    <div>
                      <select
                        name="shippingState"
                        value={values.shippingState}
                        onChange={handleChange}
                        className="p-[0.6rem] w-full rounded-md"
                      >
                        <option value="" disabled selected hidden>
                          State
                        </option>

                        {usaStates.map((state) => (
                          <option key={state} value={state}>
                            {state}
                          </option>
                        ))}
                      </select>
                      <ShowValidationError error={errors.shippingState} />
                    </div>

                    <div>
                      <input
                        type="text"
                        name="shippingZip"
                        value={values.shippingZip}
                        onChange={handleChange}
                        placeholder="Zip"
                        className="p-2 w-full rounded-md"
                        // onBlur={handleBlur}
                      />
                      <ShowValidationError error={errors.shippingZip} />
                    </div>
                  </div>
                  <div className="w-full flex flex-col items-start gap-2">
                    <div className="w-full">
                      <select
                        name="shippingCountry"
                        onChange={handleChange}
                        value={values.shippingCountry || "United States"}
                        className="p-2 w-full rounded-md"
                        // onBlur={handleBlur}
                      >
                        <option value=""></option>
                        {countries.map((name) => (
                          <option key={name} value={name}>
                            {name}
                          </option>
                        ))}
                      </select>
                      <ShowValidationError error={errors.shippingState} />
                    </div>
                    <h1 className="text-[#a1a1a1] text-md mt-6">Card Information</h1>
                    <div className="w-full">
                      <input
                        type="text"
                        name="cardHolderName"
                        value={values.cardHolderName}
                        onChange={handleChange}
                        placeholder="Cardholder Name"
                        className="p-2 w-full rounded-md"
                      />
                      <ShowValidationError error={errors.cardHolderName} />
                    </div>
                    <div className="w-full bg-white p-2 h-9 rounded-md">
                      <CheckoutForm />
                    </div>
                    <div className="w-full p-2  md:mt-2 rounded-md">
                      <div className="w-full flex justify-between gap-2 flex-wrap md:flex-nowrap">
                        <div className="w-full">
                          <h1 className="text-[#a1a1a1] text-md mt-2">Coupon Code</h1>
                          <div className="flex justify-between items-center gap-2 mt-2">
                            <input
                              type="text"
                              name="coupon"
                              value={values.coupon}
                              // onChange={handleChange}
                              onChange={(e) => {
                                setValues((prevValues) => ({
                                  ...prevValues,
                                  coupon: e.target.value,
                                }));
                                // handleCouponChange(e.target.value);
                              }}
                              placeholder="PROMO CODE (OPTIONAL)"
                              className={`w-full p-2 rounded-md uppercase ${couponClass} `}
                            />
                            <button
                              type="button"
                              className="w-72 flex justify-center items-center whitespace-nowrap gap-1 bg-storygreen text-white font-bold rounded-md text-xs md:text-sm h-9"
                              onClick={() => handleApplyPromoCode(values.coupon)}
                              // disabled={!selectedPlanDescription}
                            >
                              Apply Coupon {couponLoader && <Loader loader={couponLoader} />}
                            </button>
                          </div>
                        </div>
                        <div className="w-[30%]">
                          <h1 className="text-[#a1a1a1] text-md mt-2">Book Quantity</h1>
                          <input
                            type="number"
                            name="qty"
                            min={1}
                            value={values.qty}
                            readOnly={singleBookPlan}
                            onChange={handleChange}
                            placeholder="Quantity"
                            className="mt-2 w-full p-2 rounded-md"
                            // min="1"
                            max="100"
                            required
                            pattern="[1-9][0-9]*"
                            // onBlur={handleBlur}
                          />
                        </div>
                      </div>
                      <div className="flex items-baseline mt-8">
                        <input
                          type="checkbox"
                          name="terms"
                          checked={isBillingSameAsShipping}
                          onChange={(e) => setIsBillingSameAsShipping(e.target.checked)}
                          className="mr-2 rounded-md"
                        />
                        <label className="flex text-start">Billing info is same as shipping</label>
                      </div>
                      {!isBillingSameAsShipping && (
                        <div className="mt-8 text-start w-full flex flex-col items-start gap-2">
                          <h1 className="text-[#a1a1a1] text-md font-bold mt-6">Billing Address</h1>
                          <input
                            type="text"
                            name="billingCardHolderName"
                            value={values.billingCardHolderName}
                            onChange={handleChange}
                            placeholder="Name"
                            className="p-2 w-full rounded-md"
                          />
                          <ShowValidationError error={errors.billingCardHolderName} />
                          <input
                            type="text"
                            name="billingAddress1"
                            value={values.billingAddress1}
                            onChange={handleChange}
                            placeholder="Address 1"
                            className="p-2 w-full rounded-md"
                          />
                          <ShowValidationError error={errors.billingAddress1} />
                          <input
                            type="text"
                            name="billingAddress2"
                            value={values.billingAddress2}
                            onChange={handleChange}
                            placeholder="Address 2"
                            className="p-2 w-full rounded-md"
                          />

                          <div className="flex gap-6 mt-1">
                            <div>
                              <input
                                type="text"
                                name="billingCity"
                                value={values.billingCity}
                                onChange={handleChange}
                                placeholder="City"
                                className="p-2 w-full rounded-md"
                              />
                              <ShowValidationError error={errors.billingCity} />
                            </div>
                            <div>
                              <select
                                name="billingState"
                                value={values.billingState}
                                onChange={handleChange}
                                className="p-2 w-full rounded-md"
                              >
                                <option value=""></option>
                                {usaStates.map((state) => (
                                  <option key={state} value={state}>
                                    {state}
                                  </option>
                                ))}
                              </select>
                              <ShowValidationError error={errors.billingState} />
                            </div>
                            <div>
                              <input
                                type="text"
                                name="billingPostalCode"
                                value={values.billingPostalCode}
                                onChange={handleChange}
                                placeholder="Zip"
                                className="p-2 w-full rounded-md"
                              />
                              <ShowValidationError error={errors.billingPostalCode} />
                            </div>
                          </div>
                        </div>
                      )}
                      <SubmitButton
                        type="submit"
                        title="Purchase"
                        loader={paymentLoader}
                        cancelOperation={goBack}
                      />
                      <p className="text-base mt-2 italic">
                        Please note that the shipping and handling cost is <strong>${shippingCost}</strong>,
                        and all packages are shipped via the{" "}
                        <strong>United States Postal Service (USPS)</strong> for timely and secure delivery.
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer className="max-w-100" />
    </div>
  );
};

export default BookPurchase;
