import { callAPI } from "../services/apiService";
import CryptoJS from "crypto-js";
import { LOCAL_STORAGE_STORY_STEP } from "./Constant";

export const getStoryWordsCount = (age) => {
  const wordRanges = {
    "0-2_years": { min: 0, max: 50 },
    "3-6_years": { min: 500, max: 1000 },
    "7-9_years": { min: 2000, max: 4000 },
    "10-12_years": { min: 3000, max: 5000 },
    "13-14_years": { min: 4000, max: 6000 },
  };

  return wordRanges[age] || { min: 0, max: 0 };
};

export const generatePDF = async (bookId) => {
  try {
    const response = await callAPI(`api/pdf-generator/pdf?bookId=${bookId}`); //await fetch(`${process.env.REACT_APP_API_URL}/api/pdf-generator/pdf?bookId=${bookId}`);
    return response?.pdfUrl;
  } catch (error) {
    console.error("Error generating PDF:", error);
    return null;
  }
};

export const getUSStates = () => {
  return [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];
};



// export const getCountries = () => {
//   return [
//     "United States",
//     "Abu Dhabi (United Arab Emirates)",
//     "Admiralty Islands (Papua New Guinea)",
//     "Afghanistan",
//     "Aitutaki, Cook Islands (New Zealand)",
//     "Ajman (United Arab Emirates)",
//     "Aland Island (Finland)",
//     "Albania",
//     "Alberta (Canada)",
//     "Alderney (Channel Islands) (United Kingdom)",
//     "Algeria",
//     "Alhucemas (Spain)",
//     "Alofi Island (New Caledonia)",
//     "American Samoa, United States",
//     "Andaman Islands (India)",
//     "Andorra",
//     "Angola",
//     "Anguilla",
//     "Anjouan (Comoros)",
//     "Annobon Island (Equatorial Guinea)",
//     "Antigua (Antigua and Barbuda)",
//     "Antigua and Barbuda",
//     "Argentina",
//     "Armenia",
//     "Aruba",
//     "Ascension",
//     "Astypalaia (Greece)",
//     "Atafu (Samoa)",
//     "Atiu, Cook Islands (New Zealand)",
//     "Australia",
//     "Austria",
//     "Avarua (New Zealand)",
//     "Azerbaijan",
//     "Azores (Portugal)",
//     "Bahamas",
//     "Bahrain",
//     "Balearic Islands (Spain)",
//     "Balochistan (Pakistan)",
//     "Bangladesh",
//     "Banks Island (Vanuatu)",
//     "Barbados",
//     "Barbuda (Antigua and Barbuda)",
//     "Barthelemy (Guadeloupe)",
//     "Belarus",
//     "Belgium",
//     "Belize",
//     "Benin",
//     "Bermuda",
//     "Bhutan",
//     "Bismark Archipelago (Papua New Guinea)",
//     "Bolivia",
//     "Bonaire, Sint Eustatius, and Saba",
//     "Bora Bora (French Polynesia)",
//     "Borneo (Indonesia)",
//     "Bosnia-Herzegovina",
//     "Botswana",
//     "Bougainville (Papua New Guinea)",
//     "Bourbon (Reunion)",
//     "Brazil",
//     "British Columbia (Canada)",
//     "British Guiana (Guyana)",
//     "British Virgin Islands",
//     "Brunei Darussalam",
//     "Buka (Papua New Guinea)",
//     "Bulgaria",
//     "Burkina Faso",
//     "Burma",
//     "Burundi",
//     "Caicos Islands (Turks and Caicos Islands)",
//     "Cambodia",
//     "Cameroon",
//     "Canada",
//     "Canary Islands (Spain)",
//     "Canton Island (Kiribati)",
//     "Cape Verde",
//     "Cayman Islands",
//     "Central African Republic",
//     "Ceuta (Spain)",
//     "Ceylon (Sri Lanka)",
//     "Chad",
//     "Chaferinas Islands (Spain)",
//     "Chalki (Greece)",
//     "Channel Islands (Jersey, Guernsey, Alderney, and Sark) (United Kingdom)",
//     "Chile",
//     "China",
//     "Christiansted, US Virgin Islands, United States",
//     "Christmas Island (Australia)",
//     "Christmas Island (Kiribati)",
//     "Chuuk, Micronesia, United States",
//     "Cocos Island (Australia)",
//     "Colombia",
//     "Comoros",
//     "Congo, Democratic Republic of the",
//     "Congo, Republic of the",
//     "Cook Islands (New Zealand)",
//     "Corisco Island (Equatorial Guinea)",
//     "Corsica (France)",
//     "Costa Rica",
//     "Cote d’Ivoire",
//     "Crete (Greece)",
//     "Croatia",
//     "Cuba",
//     "Cumino Island (Malta)",
//     "Curacao",
//     "Cyjrenaica (Libya)",
//     "Cyprus",
//     "Czech Republic",
//     "Dahomey (Benin)",
//     "Damao (India)",
//     "Danger Islands (New Zealand)",
//     "Denmark",
//     "Desirade Island (Guadeloupe)",
//     "Diu (India)",
//     "Djibouti",
//     "Dodecanese Islands (Greece)",
//     "Doha (Qatar)",
//     "Dominica",
//     "Dominican Republic",
//     "Dubai (United Arab Emirates)",
//     "East Timor (Timor-Leste)",
//     "Ebeye, Marshall Islands, United States",
//     "Ecuador",
//     "Egypt",
//     "Eire (Ireland)",
//     "El Salvador",
//     "Ellice Islands (Tuvalu)",
//     "Elobey Islands (Equatorial Guinea)",
//     "Enderbury Island (Kiribati)",
//     "England (United Kingdom)",
//     "Equatorial Guinea",
//     "Eritrea",
//     "Estonia",
//     "Eswatini",
//     "Ethiopia",
//     "Fakaofo (Samoa)",
//     "Falkland Islands",
//     "Fanning Island (Kiribati)",
//     "Faroe Islands",
//     "Fernando Po (Equatorial Guinea)",
//     "Fezzan (Libya)",
//     "Fiji",
//     "Finland",
//     "Formosa (Taiwan)",
//     "France",
//     "Frederiksted, US Virgin Islands, United States",
//     "French Guiana",
//     "French Oceania (French Polynesia)",
//     "French Polynesia",
//     "French West Indies (Guadeloupe)",
//     "French West Indies (Martinique)",
//     "Friendly Islands (Tonga)",
//     "Fujairah (United Arab Emirates)",
//     "Futuna (Wallis and Futuna Islands)",
//     "Gabon",
//     "Gambia",
//     "Gambier (French Polynesia)",
//     "Georgia, Republic of",
//     "Germany",
//     "Ghana",
//     "Gibraltar",
//     "Gilbert Islands (Kiribati)",
//     "Goa (India)",
//     "Gozo Island (Malta)",
//     "Grand Comoro (Comoros)",
//     "Great Britain (United Kingdom)",
//     "Greece",
//     "Greenland",
//     "Grenada",
//     "Grenadines (Saint Vincent and the Grenadines)",
//     "Guadeloupe",
//     "Guam, United States",
//     "Guatemala",
//     "Guernsey (Channel Islands) (United Kingdom)",
//     "Guinea",
//     "Guinea–Bissau",
//     "Guyana",
//     "Hainan Island (China)",
//     "Haiti",
//     "Hashemite Kingdom (Jordan)",
//     "Hervey, Cook Islands (New Zealand)",
//     "Hivaoa (French Polynesia)",
//     "Holland (Netherlands)",
//     "Honduras",
//     "Hong Kong",
//     "Huahine (French Polynesia)",
//     "Huan Island (New Caledonia)",
//     "Hungary",
//     "Iceland",
//     "India",
//     "Indonesia",
//     "Iran",
//     "Iraq",
//     "Ireland",
//     "Irian Barat (Indonesia)",
//     "Isle of Man (United Kingdom)",
//     "Isle of Pines (New Caledonia)",
//     "Isle of Pines, West Indies (Cuba)",
//     "Israel",
//     "Issas (Djibouti)",
//     "Italy",
//     "Ivory Coast (Cote d’Ivoire)",
//     "Jamaica",
//     "Japan",
//     "Jersey (Channel Islands) (United Kingdom)",
//     "Johore (Malaysia)",
//     "Jordan",
//     "Kalymnos (Greece)",
//     "Kampuchea (Cambodia)",
//     "Karpathos (Greece)",
//     "Kassos (Greece)",
//     "Kastellorizon (Greece)",
//     "Kazakhstan",
//     "Kedah (Malaysia)",
//     "Keeling Islands (Australia)",
//     "Kelantan (Malaysia)",
//     "Kenya",
//     "Kingshill, US Virgin Islands, United States",
//     "Kiribati",
//     "Korea, Democratic People’s Republic of (North Korea)",
//     "Korea, Republic of (South Korea)",
//     "Koror (Palau), United States",
//     "Kos (Greece)",
//     "Kosovo, Republic of",
//     "Kosrae, Micronesia, United States",
//     "Kowloon (Hong Kong)",
//     "Kuwait",
//     "Kwajalein, Marshall Islands, United States",
//     "Kyrgyzstan",
//     "Labrador (Canada)",
//     "Labuan (Malaysia)",
//     "Laos",
//     "Latvia",
//     "Lebanon",
//     "Leipsos (Greece)",
//     "Leros (Greece)",
//     "Les Saints Island (Guadeloupe)",
//     "Lesotho",
//     "Liberia",
//     "Libya",
//     "Liechtenstein",
//     "Lithuania",
//     "Lord Howe Island (Australia)",
//     "Loyalty Islands (New Caledonia)",
//     "Luxembourg",
//     "Macao",
//     "Macau (Macao)",
//     "Madagascar",
//     "Madeira Islands (Portugal)",
//     "Majuro, Marshall Islands, United States",
//     "Malacca (Malaysia)",
//     "Malawi",
//     "Malaysia",
//     "Maldives",
//     "Mali",
//     "Malta",
//     "Manahiki (New Zealand)",
//     "Manchuria (China)",
//     "Manitoba (Canada)",
//     "Manua Islands, American Samoa, United States (Domestic Mail)",
//     "Marie Galante (Guadeloupe)",
//     "Marquesas Islands (French Polynesia)",
//     "Marshall Islands, Republic of, United States (Domestic Mail)",
//     "Martinique",
//     "Mauritania",
//     "Mauritius",
//     "Mayotte (France)",
//     "Melilla (Spain)",
//     "Mexico",
//     "Micronesia, Federated States of, United States (Domestic Mail)",
//     "Miquelon (Saint Pierre and Miquelon)",
//     "Moheli (Comoros)",
//     "Moldova",
//     "Monaco (France)",
//     "Mongolia",
//     "Montenegro",
//     "Montserrat",
//     "Moorea (French Polynesia)",
//     "Morocco",
//     "Mozambique",
//     "Muscat (Oman)",
//     "Myanmar (Burma)",
//     "Namibia",
//     "Nansil Islands (Japan)",
//     "Nauru",
//     "Negri Sembilan (Malaysia)",
//     "Nepal",
//     "Netherlands",
//     "Nevis (Saint Kitts and Nevis)",
//     "New Britain (Papua New Guinea)",
//     "New Brunswick (Canada)",
//     "New Caledonia",
//     "New Hanover (Papua New Guinea)",
//     "New Hebrides (Vanuatu)",
//     "New Ireland (Papua New Guinea)",
//     "New South Wales (Australia)",
//     "New Zealand",
//     "Newfoundland (Canada)",
//     "Nicaragua",
//     "Niger",
//     "Nigeria",
//     "Nissiros (Greece)",
//     "Niue (New Zealand)",
//     "Norfolk Island (Australia)",
//     "North Borneo (Malaysia)",
//     "North Korea (Korea, Democratic People’s Republic of)",
//     "North Macedonia, Republic of",
//     "Northern Ireland (United Kingdom)",
//     "Northern Mariana Islands, Commonwealth of, United States (Domestic Mail)",
//     "Northwest Territory (Canada)",
//     "Norway",
//     "Nova Scotia (Canada)",
//     "Nukahiva (French Polynesia)",
//     "Nukunonu (Samoa)",
//     "Ocean Island (Kiribati)",
//     "Okinawa (Japan)",
//     "Oman",
//     "Ontario (Canada)",
//     "Pago Pago, American Samoa, United States (Domestic Mail)",
//     "Pahang (Malaysia)",
//     "Pakistan",
//     "Palau, United States (Domestic Mail)",
//     "Palmerston, Avarua (New Zealand)",
//     "Panama",
//     "Papua New Guinea",
//     "Paraguay",
//     "Parry, Cook Islands (New Zealand)",
//     "Patmos (Greece)",
//     "Pemba (Tanzania)",
//     "Penang (Malaysia)",
//     "Penghu Islands (Taiwan)",
//     "Penon de Velez de la Gomera (Spain)",
//     "Penrhyn, Tongareva (New Zealand)",
//     "Perak (Malaysia)",
//     "Perlis (Malaysia)",
//     "Persia (Iran)",
//     "Peru",
//     "Pescadores Islands (Taiwan)",
//     "Petite Terre (Guadeloupe)",
//     "Philippines",
//     "Pitcairn Island",
//     "Pohnpei, Micronesia, United States (Domestic Mail)",
//     "Poland",
//     "Portugal",
//     "Prince Edward Island (Canada)",
//     "Puerto Rico, United States (Domestic Mail)",
//     "Pukapuka (New Zealand)",
//     "Qatar",
//     "Quebec (Canada)",
//     "Queensland (Australia)",
//     "Quemoy (Taiwan)",
//     "Raiatea (French Polynesia)",
//     "Rakaanga (New Zealand)",
//     "Rapa (French Polynesia)",
//     "Rarotonga, Cook Islands (New Zealand)",
//     "Ras al Kaimah (United Arab Emirates)",
//     "Redonda (Antigua and Barbuda)",
//     "Reunion",
//     "Rio Muni (Equatorial Guinea)",
//     "Rodos (Greece)",
//     "Rodrigues (Mauritius)",
//     "Romania",
//     "Rota, Northern Mariana Islands, United States (Domestic Mail)",
//     "Russia",
//     "Rwanda",
//     "Saba (Bonaire, Sint Eustatius, and Saba)",
//     "Sabah (Malaysia)",
//     "Saint Barthelemy (Guadeloupe)",
//     "Saint Bartholomew (Guadeloupe)",
//     "Saint Croix, US Virgin Islands, United States (Domestic Mail)",
//     "Saint Helena",
//     "Saint John, US Virgin Islands, United States (Domestic Mail)",
//     "Saint Kitts and Nevis",
//     "Saint Lucia",
//     "Saint Martin (French) (Guadeloupe)",
//     "Saint Pierre and Miquelon",
//     "Saint Thomas, US Virgin Islands, United States (Domestic Mail)",
//     "Saint Vincent and the Grenadines",
//     "Sainte Marie de Madagascar (Madagascar)",
//     "Saipan, Northern Mariana Islands, United States (Domestic Mail)",
//     "Salvador (El Salvador)",
//     "Samoa",
//     "Samoa, American, United States (Domestic Mail)",
//     "San Marino",
//     "Santa Cruz Islands (Solomon Islands)",
//     "Sao Tome and Principe",
//     "Sarawak (Malaysia)",
//     "Sark (Channel Islands) (United Kingdom)",
//     "Saskatchewan (Canada)",
//     "Saudi Arabia",
//     "Savage Island, Niue (New Zealand)",
//     "Savaii Island (Samoa)",
//     "Scotland (United Kingdom)",
//     "Seberang Perai (Malaysia)",
//     "Selangor (Malaysia)",
//     "Senegal",
//     "Serbia, Republic of",
//     "Seychelles",
//     "Sharja (United Arab Emirates)",
//     "Shikoku (Japan)",
//     "Sierra Leone",
//     "Sikkim (India)",
//     "Singapore",
//     "Sint Eustatius (Bonaire, Sint Eustatius, and Saba)",
//     "Sint Maarten (Dutch)",
//     "Slovak Republic (Slovakia)",
//     "Slovenia",
//     "Society Islands (French Polynesia)",
//     "Solomon Islands",
//     "Somali Democratic Republic (Somalia)",
//     "Somalia",
//     "Somaliland (Somalia)",
//     "South Africa",
//     "South Australia (Australia)",
//     "South Georgia (Falkland Islands)",
//     "South Korea (Korea, Republic of)",
//     "South Sudan, Republic of",
//     "Spain",
//     "Spitzbergen (Norway)",
//     "Sri Lanka",
//     "Sudan",
//     "Suriname",
//     "Suwarrow Islands (New Zealand)",
//     "Swain’s Island, American Samoa, United States (Domestic Mail)",
//     "Swan Islands (Honduras)",
//     "Swaziland (Eswatini)",
//     "Sweden",
//     "Switzerland",
//     "Symi (Greece)",
//     "Syrian Arab Republic (Syria)",
//     "Tahaa (French Polynesia)",
//     "Tahiti (French Polynesia)",
//     "Taiwan",
//     "Tajikistan",
//     "Tanzania",
//     "Tasmania (Australia)",
//     "Tchad (Chad)",
//     "Thailand",
//     "Thursday Island (Australia)",
//     "Tibet (China)",
//     "Tilos (Greece)",
//     "Timor (Indonesia)",
//     "Timor-Leste, Democratic Republic of",
//     "Tinian, Northern Mariana Islands, United States (Domestic Mail)",
//     "Tobago (Trinidad and Tobago)",
//     "Togo",
//     "Tokelau (New Zealand)",
//     "Tonga",
//     "Maldives",
//     "Mali",
//     "Malta",
//     "Manahiki (New Zealand)",
//     "Manchuria (China)",
//     "Manitoba (Canada)",
//     "Manua Islands, American Samoa, United States (Domestic Mail)",
//     "Marie Galante (Guadeloupe)",
//     "Marquesas Islands (French Polynesia)",
//     "Marshall Islands, Republic of, United States (Domestic Mail)",
//     "Martinique",
//     "Mauritania",
//     "Mauritius",
//     "Mayotte (France)",
//     "Melilla (Spain)",
//     "Mexico",
//     "Micronesia, Federated States of, United States (Domestic Mail)",
//     "Miquelon (Saint Pierre and Miquelon)",
//     "Moheli (Comoros)",
//     "Moldova",
//     "Monaco (France)",
//     "Mongolia",
//     "Montenegro",
//     "Montserrat",
//     "Moorea (French Polynesia)",
//     "Morocco",
//     "Mozambique",
//     "Muscat (Oman)",
//     "Myanmar (Burma)",
//     "Namibia",
//     "Nansil Islands (Japan)",
//     "Nauru",
//     "Negri Sembilan (Malaysia)",
//     "Nepal",
//     "Netherlands",
//     "Nevis (Saint Kitts and Nevis)",
//     "New Britain (Papua New Guinea)",
//     "New Brunswick (Canada)",
//     "New Caledonia",
//     "New Hanover (Papua New Guinea)",
//     "New Hebrides (Vanuatu)",
//     "New Ireland (Papua New Guinea)",
//     "New South Wales (Australia)",
//     "New Zealand",
//     "Newfoundland (Canada)",
//     "Nicaragua",
//     "Niger",
//     "Nigeria",
//     "Nissiros (Greece)",
//     "Niue (New Zealand)",
//     "Norfolk Island (Australia)",
//     "North Borneo (Malaysia)",
//     "North Korea (Korea, Democratic People’s Republic of)",
//     "North Macedonia, Republic of",
//     "Northern Ireland (United Kingdom)",
//     "Northern Mariana Islands, Commonwealth of, United States (Domestic Mail)",
//     "Northwest Territory (Canada)",
//     "Norway",
//     "Nova Scotia (Canada)",
//     "Nukahiva (French Polynesia)",
//     "Nukunonu (Samoa)",
//     "Ocean Island (Kiribati)",
//     "Okinawa (Japan)",
//     "Oman",
//     "Ontario (Canada)",
//     "Pago Pago, American Samoa, United States (Domestic Mail)",
//     "Pahang (Malaysia)",
//     "Pakistan",
//     "Palau, United States (Domestic Mail)",
//     "Palmerston, Avarua (New Zealand)",
//     "Panama",
//     "Papua New Guinea",
//     "Paraguay",
//     "Parry, Cook Islands (New Zealand)",
//     "Patmos (Greece)",
//     "Pemba (Tanzania)",
//     "Penang (Malaysia)",
//     "Penghu Islands (Taiwan)",
//     "Penon de Velez de la Gomera (Spain)",
//     "Penrhyn, Tongareva (New Zealand)",
//     "Perak (Malaysia)",
//     "Perlis (Malaysia)",
//     "Persia (Iran)",
//     "Peru",
//     "Pescadores Islands (Taiwan)",
//     "Petite Terre (Guadeloupe)",
//     "Philippines",
//     "Pitcairn Island",
//     "Pohnpei, Micronesia, United States (Domestic Mail)",
//     "Poland",
//     "Portugal",
//     "Prince Edward Island (Canada)",
//     "Puerto Rico, United States (Domestic Mail)",
//     "Pukapuka (New Zealand)",
//     "Qatar",
//     "Quebec (Canada)",
//     "Queensland (Australia)",
//     "Quemoy (Taiwan)",
//     "Raiatea (French Polynesia)",
//     "Rakaanga (New Zealand)",
//     "Rapa (French Polynesia)",
//     "Rarotonga, Cook Islands (New Zealand)",
//     "Ras al Kaimah (United Arab Emirates)",
//     "Redonda (Antigua and Barbuda)",
//     "Reunion",
//     "Rio Muni (Equatorial Guinea)",
//     "Rodos (Greece)",
//     "Rodrigues (Mauritius)",
//     "Romania",
//     "Rota, Northern Mariana Islands, United States (Domestic Mail)",
//     "Russia",
//     "Rwanda",
//     "Saba (Bonaire, Sint Eustatius, and Saba)",
//     "Sabah (Malaysia)",
//     "Saint Barthelemy (Guadeloupe)",
//     "Saint Bartholomew (Guadeloupe)",
//     "Saint Croix, US Virgin Islands, United States (Domestic Mail)",
//     "Saint Helena",
//     "Saint John, US Virgin Islands, United States (Domestic Mail)",
//     "Saint Kitts and Nevis",
//     "Saint Lucia",
//     "Saint Martin (French) (Guadeloupe)",
//     "Saint Pierre and Miquelon",
//     "Saint Thomas, US Virgin Islands, United States (Domestic Mail)",
//     "Saint Vincent and the Grenadines",
//     "Sainte Marie de Madagascar (Madagascar)",
//     "Saipan, Northern Mariana Islands, United States (Domestic Mail)",
//     "Salvador (El Salvador)",
//     "Samoa",
//     "Samoa, American, United States (Domestic Mail)",
//     "San Marino",
//     "Santa Cruz Islands (Solomon Islands)",
//     "Sao Tome and Principe",
//     "Sarawak (Malaysia)",
//     "Sark (Channel Islands) (United Kingdom)",
//     "Saskatchewan (Canada)",
//     "Saudi Arabia",
//     "Savage Island, Niue (New Zealand)",
//     "Savaii Island (Samoa)",
//     "Scotland (United Kingdom)",
//     "Seberang Perai (Malaysia)",
//     "Selangor (Malaysia)",
//     "Senegal",
//     "Serbia, Republic of",
//     "Seychelles",
//     "Sharja (United Arab Emirates)",
//     "Shikoku (Japan)",
//     "Sierra Leone",
//     "Sikkim (India)",
//     "Singapore",
//     "Sint Eustatius (Bonaire, Sint Eustatius, and Saba)",
//     "Sint Maarten (Dutch)",
//     "Slovak Republic (Slovakia)",
//     "Slovenia",
//     "Society Islands (French Polynesia)",
//     "Solomon Islands",
//     "Somali Democratic Republic (Somalia)",
//     "Somalia",
//     "Somaliland (Somalia)",
//     "South Africa",
//     "South Australia (Australia)",
//     "South Georgia (Falkland Islands)",
//     "South Korea (Korea, Republic of)",
//     "South Sudan, Republic of",
//     "Spain",
//     "Spitzbergen (Norway)",
//     "Sri Lanka",
//     "Sudan",
//     "Suriname",
//     "Suwarrow Islands (New Zealand)",
//     "Swain’s Island, American Samoa, United States (Domestic Mail)",
//     "Swan Islands (Honduras)",
//     "Swaziland (Eswatini)",
//     "Sweden",
//     "Switzerland",
//     "Symi (Greece)",
//     "Syrian Arab Republic (Syria)",
//     "Tahaa (French Polynesia)",
//     "Tahiti (French Polynesia)",
//     "Taiwan",
//     "Tajikistan",
//     "Tanzania",
//     "Tasmania (Australia)",
//     "Tchad (Chad)",
//     "Thailand",
//     "Thursday Island (Australia)",
//     "Tibet (China)",
//     "Tilos (Greece)",
//     "Timor (Indonesia)",
//     "Timor-Leste, Democratic Republic of",
//     "Tinian, Northern Mariana Islands, United States (Domestic Mail)",
//     "Tobago (Trinidad and Tobago)",
//     "Togo",
//     "Tokelau (New Zealand)",
//     "Tonga",
//     "Tongareva (New Zealand)",
//     "Tori Shima (Japan)",
//     "Torres Island (Vanuatu)",
//     "Trans-Jordan, Hashemite Kingdom (Jordan)",
//     "Transkei (South Africa)",
//     "Trengganu (Malaysia)",
//     "Trinidad and Tobago",
//     "Tripolitania (Libya)",
//     "Tristan da Cunha",
//     "Trucial States (United Arab Emirates)",
//     "Tuamotou (French Polynesia)",
//     "Tubuai (French Polynesia)",
//     "Tunisia",
//     "Turkiye, Republic of",
//     "Turkmenistan",
//     "Turks and Caicos Islands",
//     "Tutuila Island, American Samoa, United States (Domestic Mail)",
//     "Tuvalu",
//     "Uganda",
//     "Ukraine",
//     "Umm al Quaiwain (United Arab Emirates)",
//     "Umm Said (Qatar)",
//     "United Arab Emirates",
//     "United Kingdom of Great Britain and Northern Ireland",
//     "United Nations, New York, United States (Domestic Mail)",
//     "Upolu Island (Samoa)",
//     "Uruguay",
//     "Uzbekistan",
//     "Vanuatu",
//     "Vatican City",
//     "Venezuela",
//     "Victoria (Australia)",
//     "Vietnam",
//     "Virgin Islands (British)",
//     "Virgin Islands (US), United States (Domestic Mail)",
//     "Wales (United Kingdom)",
//     "Wallis and Futuna Islands",
//     "Wellesley, Province (Malaysia)",
//     "West New Guinea (Indonesia)",
//     "Western Australia (Australia)",
//     "Yap, Micronesia, United States (Domestic Mail)",
//     "Yemen",
//     "Yukon Territory (Canada)",
//     "Zafarani Islands (Spain)",
//     "Zambia",
//     "Zanzibar (Tanzania)",
//     "Zimbabwe",
//   ];
// };


export const getCountries = () => {
  return [
    "Australia",
    "Austria",
    "Belgium",
    "Brazil",
    "Bulgaria",
    "Canada",
    "Croatia",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Estonia",
    "Finland",
    "France",
    "Germany",
    "Gibraltar",
    "Greece",
    "Hong Kong",
    "Hungary",
    "India",
    "Indonesia",
    "Ireland",
    "Italy",
    "Japan",
    "Latvia",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Malaysia",
    "Malta",
    "Mexico",
    "Netherlands",
    "New Zealand",
    "Norway",
    "Poland",
    "Portugal",
    "Romania",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Spain",
    "Sweden",
    "Switzerland",
    "Thailand",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
  ];
};





export const encryptToken = (token, secretKey) => {
  // Generate a random IV (16 bytes for AES)
  const iv = CryptoJS.lib.WordArray.random(16);

  // Encrypt the token with AES using the secret key and IV
  const encryptedData = CryptoJS.AES.encrypt(token, secretKey, { iv }).toString();

  // Convert IV to base64 string
  const ivString = CryptoJS.enc.Base64.stringify(iv);

  // Prepend IV to the encrypted data, separated by a delimiter (you can choose your own delimiter)
  const encryptedTokenWithIV = ivString + "|" + encryptedData;

  return encryptedTokenWithIV;
};

export const convertTimestampToDateTime = (ts, onlyDate = false) => {
  if (ts) {
    const timestamp = new Date(ts._seconds * 1000 + ts._nanoseconds / 1000000);
    // return timestamp.toLocaleString();
    if (onlyDate) {
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const month = monthNames[timestamp.getMonth()];
      const day = timestamp.getDate();
      const year = timestamp.getFullYear();

      return `${month} ${day}, ${year}`;
    } else {
      return timestamp.toLocaleString();
    }
  } else return "";
};

// export const goToTopOfPage = () => {
//   window.scrollTo({
//     top: 0,
//     behavior: "smooth",
//   });
// };


export const goToTopOfPage = (scrollElement= window) => {
// console.log("go to top called", scrollElement);
scrollElement.scrollTo(0, 0);
  // scrollElement.scrollTo({
  //   top: 0,
  //   behavior: "smooth",
  // });

}

export const scrollToArea = (targetRefs, id) => {
  //Make Sure timeout is important
  setTimeout(() => {
    const targetDiv = targetRefs.current[id];
    if (targetDiv) targetDiv.scrollIntoView({ behavior: "auto" });
  }, 100);
};

export const getCurrentYear = () => {
  const currentYear = new Date().getFullYear();
  return currentYear;
};

export const setCurrentStoryStep = (step) => {
  localStorage.setItem(LOCAL_STORAGE_STORY_STEP, step);
};

export const makeDropdownOptions = (options) => {
  return options.map((option) => {
    return <option value={option.value}>{option.label}</option>;
  });
};

export const getCurrentTimestamp = () => {
  const now = new Date();
  const seconds = Math.floor(now.getTime() / 1000);
  const nanoseconds = now.getMilliseconds() * 1e6;

  return {
    _seconds: seconds,
    _nanoseconds: nanoseconds,
  };
};

export const replaceFromString = (target, replaceItems) => {
  const escapedReplaceItems = replaceItems.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");

  // Create a regular expression that matches any of the characters in replaceItems
  const regex = new RegExp(`[${escapedReplaceItems}]`, "g");

  // Replace all matches with a space
  const replacedString = target.replace(regex, " ");

  return replacedString;
};

export const capitalizeFirstCharacter = (str) => {
  if (!str) return str; // If the string is empty or null, return it as is.
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const secondsToMinutesAndSeconds = (totalSeconds) => {
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(seconds).padStart(2, "0");

  return `${formattedMinutes}:${formattedSeconds}`;
};

export const convertMidjourneyTimestampToDateTime = (dateStr) => {
  try {
    const date = new Date(dateStr);

    // Extract the parts of the date
    const hours = date.getUTCHours().toString().padStart(2, "0");
    const minutes = date.getUTCMinutes().toString().padStart(2, "0");
    const day = date.getUTCDate().toString().padStart(2, "0");
    const month = (date.getUTCMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
    const year = date.getUTCFullYear();

    // Format the date as MM:HH dd/mm/yyyy
    return `${hours}: ${minutes} ${day}/${month}/${year}`;
  } catch (error) {
    console.log("error converting timestamp", error);

    return "";
  }
};
